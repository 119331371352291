export const API_URL = process.env.REACT_APP_API_URL as string;
export const JWT_SECRET = '123456' as string;
export const FACEBOOK_APP_ID = process.env.REACT_APP_LOGIN_FACEBOOK_APPID as string;
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;
export const GOOGLE_TOKEN = process.env.GOOGLE_ACCESS_TOKEN as string;
export const FB_TOKEN = process.env.FB_ACCESS_TOKEN as string;
export const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI as string;
export const FB_URL = process.env.REACT_APP_FACEBOOK_URL as string;
export const INSTAGRAM_URL = process.env.REACT_APP_INSTAGRAM_URL as string;
export const TWITTER_URL = process.env.REACT_APP_TWITTER_URL as string;
export const LINKEDIN_URL = process.env.REACT_APP_LINKEDIN_URL as string;
export const API_KEY = process.env.REACT_APP_API_KEY as string;
export const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN as string;
export const PROJECT_ID = process.env.REACT_APP_PROJECT_ID as string;
export const STORAGE_BUCKET = process.env.REACT_APP_STORAGE_BUCKET as string;
export const MESSAGING_SENDER_ID = process.env.REACT_APP_MESSAGING_SENDER_ID as string;
export const APP_ID = process.env.REACT_APP_APP_ID as string;
export const MEASUREMENT_ID = process.env.REACT_APP_MEASUREMENT_ID as string;
export const GEO_API_KEY = process.env.REACT_APP_GEO_API_KEY as string;
export const INSURANCE_ACTIVE = process.env.REACT_APP_INSURANCE_ACTIVE;
export const INDIAPOST_ACTIVE = process.env.REACT_APP_INDIAPOST_ACTIVE;
export const HYPERLOCAL_ACTIVE = process.env.REACT_APP_INDIAPOST_HYPERLOCAL_ACTIVE as string;
export const SITE_DISABLED = process.env.REACT_APP_SITE_DISABLED as string;
export const COMING_SOON = process.env.REACT_APP_COMING_SOON as string;
export const CUTTOFF_XPRESSBEES = process.env.REACT_APP_CUTTOFF_XPRESSBEES as string;
export const CUTTOFF_AMAZON = process.env.REACT_APP_CUTTOFF_AMAZON as string;
export const CUTTOFF_CRITICALOG = process.env.REACT_APP_CUTTOFF_CRITICALOG as string;
export const CUTTOFF_SHADOWFAX = process.env.REACT_APP_CUTTOFF_SHADOWFAX as string;
export const CUTTOFF_ECOM_EXPRESS = process.env.REACT_APP_CUTTOFF_ECOM_EXPRESS as string;
export const CUTTOFF_DELHIVERY = process.env.REACT_APP_CUTTOFF_DELHIVERY as string;
export const CUTTOFF_DTDC = process.env.REACT_APP_CUTTOFF_DTDC as string;
export const CUTTOFF_DEFAULT = process.env.REACT_APP_CUTTOFF_DEFAULT as string;
export const CUTOFF_INDIAPOST_QWQER = process.env.REACT_APP_CUTOFF_INDIAPOST_QWQER as string;
export const CUTOFF_INDIAPOST_DUNZO = process.env.REACT_APP_CUTOFF_INDIAPOST_DUNZO as string;
export const CUTTOFF_BLUEDART = process.env.REACT_APP_CUTTOFF_BLUEDART;
export const CUTTOFF_SMARTR = process.env.REACT_APP_CUTTOFF_SMARTR;
export const CUTOFF_INDIAPOST_SELF = process.env.REACT_APP_CUTOFF_INDIAPOST_SELF as string;
export const CUTTOFF_SHREEMARUTI = process.env.REACT_APP_CUTTOFF_SHREEMARUTI as string;
export const MARQUEE_TEXT = process.env.REACT_APP_MARQUEE_TEXT as string;
export const BILLING_ADDRESS_OTP_DISABLED = process.env
  .REACT_APP_BILLING_ADDRESS_OTP_DISABLED as string;
export const CUTTOFF_GATI = process.env.REACT_APP_CUTTOFF_GATI as string;
export const CLOUDINARY_URL = process.env.REACT_APP_CLOUDINARY_URL as string;
export const COREYO_MAIN_PORTAL = process.env.REACT_APP_COREYO_MAIN_PORTAL as string;
export const WALLET_ENABLED = process.env.REACT_APP_WALLET_ENABLED as string;
export const SALES_SUMMARY_ENABLED = process.env.REACT_APP_SALES_SUMMARY_ENABLED as string;
export const MULTIPACKAGE = process.env.REACT_APP_MULTIPACKAGE as string;
export const INTERNATIONAL_ACTIVE = process.env.REACT_APP_INTERNATIONAL_ACTIVE as string;
export const SHOW_COMMISSION_SALES_SUMMARY = process.env
  .REACT_APP_SHOW_COMMISSION_SALES_SUMMARY as string;
export const RAZORPAY_UPI_ENABLED = process.env.REACT_APP_RAZORPAY_UPI_ENABLED as string;
export const RAZORPAY_WALLET_ENABLED = process.env.REACT_APP_RAZORPAY_WALLET_ENABLED as string;
export const RAZORPAY_CARD_ENABLED = process.env.REACT_APP_RAZORPAY_CARD_ENABLED as string;
export const RAZORPAY_NETBANKING_ENABLED = process.env
  .REACT_APP_RAZORPAY_NETBANKING_ENABLED as string;
